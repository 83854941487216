


















































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import TooltipCell from '@/pages/common/grid-parts/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  changeIdSubject,
  changeIsMandatory,
  $triggerToRefreshTable,
  subjectsFilters,
  deleteSubjects,
  requestDeleteSubjects,
  $isLoading,
  $subjectsTotal,
} from '@/pages/dictionary/subjects/list/subjects-page.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  subjectsTableFields,
  searchFieldsData,
  getSubjectsActions,
} from '@/pages/dictionary/subjects/list/constants'
import {
  CommonInteractedItemParams,
  ActionsItem,
  DisplayContextMenuPayload,
  HttpOptionsType,
  RefsType,
} from '@/pages/common/types'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import { $permissions } from '@/features/session'
import { computeSortParam } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { DEFAULT_ID } from '@/pages/common/constants'
import { getActionsDisplayConditions } from '@/pages/common'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { axiosClient } from '@/lib/request'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'SubjectsGridPage',
  components: {
    TableHeader,
    Vuetable,
    GridPageHead,
    GeneralFilter,
    TooltipCell,
    ActionsButton,
    ContextMenu,
    BaseButton,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
  },
  effector: {
    $triggerToRefreshTable,
    $filterParams: subjectsFilters.store.$filterParams,
    $isLoading,
    $permissions,
    $subjectsTotal,
  },
  data() {
    return {
      interactedItemId: 0,
      showContextMenu: false,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      fields: subjectsTableFields,
      searchFields: searchFieldsData,
      total: 0,
      selectedRows: [] as number[],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/subject-app/subjects/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getSubjectsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $triggerToRefreshTable() {
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
  },
  methods: {
    changeFilter: subjectsFilters.methods.changeFilter,
    resetFilters: subjectsFilters.methods.resetFilters,
    applyFilters: subjectsFilters.methods.applyFilters,
    myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    // TODO like removeSelected
    updateTypeSubject(isMandatory: boolean) {
      changeIsMandatory(isMandatory)
      changeIdSubject(this.selectedIds[0])
    },
    onRemoveSubjects() {
      this.$permissions!.subjects_subject_subjects.can_edit
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    async removeSelectedSubject(ids: number[]) {
      await deleteSubjects(ids)
      await Vue.nextTick(() => this.$refs.vuetable.reload())
      this.removeSelection()
    },
    async sendRequestDeleteSubject(comment: string, ids: number[]) {
      await requestDeleteSubjects({ subjects: ids, ticket_comment: comment })
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) noInternetToastEvent()
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      event.preventDefault()
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getSubjectsActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    editSubject() {
      navigatePush({ name: 'subjects-edit', params: { id: `${this.selectedIds[0]}` } })
    },
  },
  mounted() {
    this.total = this.$subjectsTotal
  },
  destroyed() {
    this.resetFilters()
  },
})
