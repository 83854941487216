











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { debounce } from 'throttle-debounce'
import { GroupCourseType } from '@/pages/learning/learning-courses/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    label: String,
    blockId: Number,
    selectedGroup: Object,
    groupsList: Array,
  },
  data(): {
    searchString: string
    itemsDropdown: GroupCourseType[]
  } {
    return {
      searchString: '',
      itemsDropdown: [],
    }
  },
  watch: {
    searchString: {
      handler() {
        this.searchGroup()
      },
    },
  },
  methods: {
    filterItems(str: string, list: any) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].title.toLowerCase().indexOf(str.toLocaleLowerCase()) === -1) {
          if (list[i].leaves) {
            if (!list[i].leaves.length) {
              list.splice(i, 1)
              i--
            } else {
              list[i].leaves = this.filterItems(str, list[i].leaves)
              if (!list[i].leaves.length) {
                list.splice(i, 1)
                i--
              }
            }
          } else {
            list.splice(i, 1)
            i--
          }
        }
      }
      return list
    },
    setItems(items: GroupCourseType[]) {
      this.itemsDropdown = items
    },
    resetItem() {
      this.$emit('item-changed', null)
    },
    resetSearchString() {
      this.searchString = ''
    },
    searchStringChanged(value: string) {
      this.searchString = value
    },
    itemChanged(group: GroupCourseType) {
      console.log('itemChanged 1', group)
    },
    setGroupsList(group: GroupCourseType | null, search: string) {
      this.itemsDropdown = this.filterItems(search, group)
    },
    onSelectItem(group: GroupCourseType | null) {
      this.$emit('item-changed', group)
    },
    searchGroup: debounce(300, false, function getDebounceTask(this: any) {
      this.setGroupsList(this.groupsList, this.searchString)
    }),
  },
})
