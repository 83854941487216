


















































import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Modal from '@/ui/modal/Modal.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import StatusFilterDropdown from '@/pages/common/dropdowns/courses/status-dropdown/StatusFilterDropdown.vue'
import {
  $coursesIds,
  $coursesIdsErrorModule,
  $lessonAccessDt,
  $modalVisibility,
  cancelForm,
  coursesIdsChanged,
  lessonAccessDtChanged,
  modalVisibilityChanged,
  submitForm,
  $isDisabledAccessDtChanged,
  $isDisabledAProceed,
  $isOneSelect,
  $courseInfo,
} from './application-course-update-modal.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ApplicationsCoursesUpdateModal',
  components: {
    Modal,
    BaseTextarea,
    StatusFilterDropdown,
    BaseButton,
    DatePicker,
  },
  effector: {
    $modalVisibility,
    $coursesIds,
    idsError: $coursesIdsErrorModule.store.$error,
    $lessonAccessDt,
    $isDisabledAccessDtChanged,
    $isDisabledAProceed,
    $isOneSelect,
    $courseInfo,
  },
  methods: {
    modalVisibilityChanged,
    coursesIdsChanged,
    submitForm,
    cancelForm,
    lessonAccessDtChanged,
  },
})
